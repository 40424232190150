<template>
    <!-- eslint-disable -->
    <Form :action="action" :namaVal="nama" :emailNameVal="emailName" :peranVal="peran" :id="id" :usernameVal="username" :dataPeranVal="dataPeran" :isShowRsKlinikVal="isShowRsKlinik" :rsKlinikVal="rsKlinik" :dataRsKlinikVal="dataRsKlinik" />
</template>

<script>
import Form from './Form.vue'
import {ref} from '@vue/composition-api'
import {decrypt} from '@/libs/crypto'

export default {
    setup(props, context){
        const g = context.root

        const $http = g.$http

        var nama = ref('')
        var emailName = ref('')
        var peran = ref('')
        var username = ref('')
        var dataPeran = ref([])
        var dataRsKlinik = ref([])
        var isShowRsKlinik = ref(false)
        var rsKlinik = ref({value: null, text: '-- Pilih Opsi --'})

        const getData = () => {
            $http({
                url: '/mjmn/pengguna/ubah/'+props.id,
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    const {emailVal, namaVal, peranVal, usernameVal, dataUser, rsKlinikVal} = res.data.data
                    nama.value = namaVal
                    emailName.value = emailVal
                    peran.value = peranVal
                    username.value = usernameVal
                    dataPeran.value = dataUser.dataPeranVal
                    rsKlinik.value = rsKlinikVal
                    dataRsKlinik.value = dataUser.dataRsKlinikVal

                    let token = decrypt(peranVal)
                    if(token == 3)
                        isShowRsKlinik.value = true
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Submit Form`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        getData()

        return {
            nama, emailName, peran, username, dataPeran, isShowRsKlinik, rsKlinik, dataRsKlinik
        }
    },
    data(){
        return{
            action: 'Ubah'
        }
    },
    components: {
        Form
    },
    props: ['id']
}
</script>