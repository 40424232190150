<template>
    <div>
        <b-card no-body>
            <b-card-header>
                <b-card-title>{{action}} Data</b-card-title>
                <b-card-title class="text-primary">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="secondary"
                        class="btn-icon"
                        size="sm"
                        :to="{name: 'mjmn-pengguna'}"
                    >
                        <feather-icon icon="ArrowLeftIcon" />
                    </b-button>
                </b-card-title>
            </b-card-header>

            <b-card-body>
                <b-form class="needs-validation" @submit.prevent="submitForm">
                    <b-form-row>
                        <b-col
                            md="10"
                            offset-md="1"
                        >
                            <!-- input nama -->
                            <b-form-group
                                label="Nama"
                                label-for="nama"
                            >
                                <b-form-input
                                    id="nama"
                                    v-model="nama"
                                    :state="validateState(v$.nama)"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.nama.$silentErrors" :key="index">
                                    {{ error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <!-- input username -->
                            <b-form-group
                                label="Username"
                                label-for="username"
                            >
                                <b-form-input
                                    id="username"
                                    v-model="v$.username.$model"
                                    :state="validateState(v$.username)"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.username.$errors" :key="index">
                                    {{ error.$validator == 'uniqueUsername' ? 'Username Telah Digunakan' : error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <!-- input email -->
                            <b-form-group
                                label="Email"
                                label-for="email"
                            >
                                <b-form-input
                                    id="email"
                                    v-model="v$.emailName.$model"
                                    :state="validateState(v$.emailName)"
                                    type="email"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.emailName.$errors" :key="index">
                                    {{ error.$validator == 'uniqueEmail' ? 'Email Telah Digunakan' : error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <!-- input peran -->
                            <b-form-group
                                label="Peran"
                                label-for="peran"
                            >
                                <b-form-select
                                    v-model="v$.peran.$model"
                                    :options="dataPeran"
                                    :state="validateState(v$.peran)"
                                    @change="changePeran"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.peran.$errors" :key="index">
                                    {{ error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <!-- input rsKlinik -->
                            <b-form-group
                                label="RS / Klinik"
                                label-for="rsKlinik"
                                v-if="isShowRsKlinik"
                            >
                                <v-select
                                    id="rsKlinik"
                                    label="text"
                                    v-model="v$.rsKlinik.$model"
                                    :options="dataRsKlinik"
                                >
                                </v-select>
                                <small class="text-danger" v-for="(error, index) of v$.rsKlinik.$errors" :key="index">
                                    {{ error.$validator == 'checkRsKlinik' ? 'RS / Klinik Tidak Boleh Kosong' : error.$message }}
                                </small>
                                <!-- <b-form-select
                                    v-model="v$.rsKlinik.$model"
                                    :options="dataRsKlinik"
                                    :state="validateState(v$.rsKlinik)"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.rsKlinik.$errors" :key="index">
                                    {{ error.$validator == 'checkRsKlinik' ? 'RS / Klinik Tidak Boleh Kosong' : error.$message }}
                                </b-form-invalid-feedback> -->
                            </b-form-group>

                            <!-- input currentPassword -->
                            <b-form-group
                                label="Password Saat Ini"
                                label-for="currentPassword"
                                v-if="action == 'Ubah'"
                            >
                                <b-form-input
                                    id="currentPassword"
                                    v-model="currentPassword"
                                    type="password"
                                />
                            </b-form-group>

                            <!-- input password -->
                            <b-form-group
                                :label="'Password' + (action == 'Ubah' ? ' Baru' : '')"
                                label-for="password"
                            >
                                <b-form-input
                                    id="password"
                                    v-model="password"
                                    :state="validateState(v$.password)"
                                    type="password"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.password.$silentErrors" :key="index">
                                    {{ error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <!-- input konfirmasiPassword -->
                            <b-form-group
                                label="Konfirmasi Password"
                                label-for="konfirmasiPassword"
                            >
                                <b-form-input
                                    id="konfirmasiPassword"
                                    v-model="konfirmasiPassword"
                                    :state="validateState(v$.konfirmasiPassword)"
                                    type="password"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.konfirmasiPassword.$silentErrors" :key="index">
                                    {{ error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-col
                                cols="12"
                                class="text-right pr-0"
                            >
                                <b-button
                                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                    type="button"
                                    variant="outline-secondary"
                                    class="mr-1"
                                    @click="resetForm"
                                >
                                    Reset
                                </b-button>
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="submit"
                                    variant="primary"
                                >
                                <div v-if="isSubmit">
                                    <b-spinner small />
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div v-else>Simpan</div>
                                </b-button>
                            </b-col>
                        </b-col>
                    </b-form-row>
                </b-form>
            </b-card-body>
        </b-card>
    </div>
  
</template>

<script>
import {
    BCard, BForm, BFormRow, BCol, BFormInput, BFormValidFeedback, BFormInvalidFeedback, BCardHeader, BCardBody, BButton, BFormGroup, BCardTitle, BSpinner, BFormSelect
} from 'bootstrap-vue'
import { required, helpers, email, sameAs } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import {ref, computed, watchEffect } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import {decrypt} from '@/libs/crypto'
import vSelect from 'vue-select'

export default {
    components: {
        vSelect,
        BCard, BForm, BFormRow, BCol, BFormInput, BFormValidFeedback, BFormInvalidFeedback, BCardHeader, BCardBody, BButton, BFormGroup, BCardTitle, BSpinner, BFormSelect
    },
    props: {
        action: String,
        emailNameVal: String,
        peranVal: String,
        namaVal: String,
        currentPasswordVal: String,
        dataPeranVal: Array,
        usernameVal: String,
        id: String,
        isShowRsKlinikVal: Boolean,
        rsKlinikVal: Object,
        dataRsKlinikVal: Array
    },
    setup(props, context){
        const g = context.root

        var isSubmit = ref(false)
        const router = g.$router
        const $http = g.$http

        //form name
        var nama = ref('')
        var username = ref('')
        var emailName = ref('')
        var password = ref('')
        var konfirmasiPassword = ref('')
        var peran = ref(null)
        var currentPassword = ref('')
        var rsKlinik = ref({text: '-- Pilih Opsi --', value: null})
        var dataPeran = ref([])
        var dataRsKlinik = ref([])

        var isShowRsKlinik = ref(false)

        watchEffect(() => {
            nama.value = props.namaVal
            emailName.value = props.emailNameVal
            peran.value = props.peranVal
            username.value = props.usernameVal
            dataPeran.value = props.dataPeranVal
            isShowRsKlinik.value = props.isShowRsKlinikVal
            rsKlinik.value = props.rsKlinikVal
            dataRsKlinik.value = props.dataRsKlinikVal

            // console.log(peran.value);
        })

        const uniqueEmail = async (val) => {
            return await $http({
                url: '/mjmn/pengguna/check-email/',
                data: {
                    email: val,
                    id: props.id
                },
                method: 'post',
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(() => true )
                .catch(() => false )
                .then(res => {
                    return res
                })
        }

        const uniqueUsername = async (val) => {
            return await $http({
                url: '/mjmn/pengguna/check-username',
                data: {
                    username: val,
                    id: props.id
                },
                method: 'post',
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(() => true )
                .catch(() => false )
                .then(res => {
                    return res
                })
        }

        const checkRsKlinik = (val) => {
            let token = decrypt(peran.value)
            if(isShowRsKlinik.value && token == 3){
                if(!val.value)
                    return false;
            }
            return true;
        }

        // validation
        const requiredMsg = 'tidak boleh kosong'
        const $externalResults = ref({})
        const rules = computed(() => ({
            nama: { 
                required: helpers.withMessage('Nama '+requiredMsg, required)
            },
            username: { 
                required: helpers.withMessage('Username '+requiredMsg, required),
                uniqueUsername: helpers.withAsync(uniqueUsername)
            },
            emailName: { 
                // required: helpers.withMessage('Email '+requiredMsg, required),
                email: helpers.withMessage('Format Email Salah', email),
                uniqueEmail: helpers.withAsync(uniqueEmail)
            },
            password: props.action == 'Tambah' ? { 
                required: helpers.withMessage('Password '+requiredMsg, required)
            } : {},
            konfirmasiPassword: props.action == 'Tambah' ? { 
                required: helpers.withMessage('Konfirmasi Password '+requiredMsg, required),
                sameAsPassword: helpers.withMessage('Konfirmasi Password Tidak Sama Dengan Password', sameAs(password.value))
            } : {
                sameAsPassword: helpers.withMessage('Konfirmasi Password Tidak Sama Dengan Password Baru', sameAs(password.value))
            },
            peran: { 
                required: helpers.withMessage('Peran '+requiredMsg, required)
            },
            rsKlinik: {
                checkRsKlinik
            }
        }))

        const v$ = useVuelidate(rules, { nama, emailName, password, konfirmasiPassword, peran, username, rsKlinik }, {$externalResults, $autoDirty: true})

        const submitForm = async () => {
            isSubmit.value = true
            const isFormCorrect = await v$.value.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            //jika error validasi
            if (!isFormCorrect) {
                isSubmit.value = false
                return
            }

            // mapping data
            let data = {
                nama: nama.value,
                email: emailName.value,
                password: password.value,
                konfirmasiPassword: konfirmasiPassword.value,
                peran: peran.value,
                username: username.value,
                currentPassword: currentPassword.value,
                rsKlinik: rsKlinik.value.value,
                id: props.id
            };

            $http({
                url: '/mjmn/pengguna/submit/'+props.action,
                data,
                method: 'post',
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    router.push({name: 'mjmn-pengguna'})
                })
                .catch(err => {
                    isSubmit.value = false
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Submit Form`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const changePeran = async (val) => {
            let token = decrypt(val)
            if(token == 3 || token == 5){
                isShowRsKlinik.value = true

                $http({
                    url: '/mjmn/pengguna/get-data-rs-klinik',
                    params: {
                        rsKlinik: rsKlinik.value.value
                    },
                    headers: {
                        Authorization: 'Bearer '+g.$cookies.get('token')
                    }
                })
                    .then(res => {
                        let {dataRsKlinikVal, rsKlinikVal} = res.data.data
                        dataRsKlinik.value = dataRsKlinikVal
                        rsKlinik.value = rsKlinikVal
                    })
                    .catch(err => {
                        let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                        g.$bvToast.toast(showError || 'Something Wrong', {
                            title: `Error Field Form`,
                            variant: 'danger',
                            solid: true,
                        })
                    })
            }
            else
                isShowRsKlinik.value = false
        }

        const validateState = (item) => {
            const { $dirty, $error } = item
            return !$dirty ? null : $error ? false : null
        }

        const resetForm = () => {
            nama.value = ''
            emailName.value = ''
            peran.value = null
            password.value = ''
            konfirmasiPassword.value = ''
            username.value = ''
        }

        return {
            v$, $externalResults,
            nama, emailName, password, konfirmasiPassword, peran, currentPassword, dataPeran, username, rsKlinik, isShowRsKlinik, dataRsKlinik,
            isSubmit,
            resetForm, validateState, submitForm, changePeran
        }
    },
    directives: {
        Ripple,
    },
}
</script>

<style lang="scss" scope>
@import '@core/scss/vue/libs/vue-select.scss';
</style>